.headingBox {
  background-color: #465e9a;
  padding: 1rem 0;
}

.mainHeading {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainHeading article {
  padding: 2rem 2rem 3rem 2rem;
  text-align: center;
}

.mainHeading__preTitle {
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 2px;
  margin-bottom: 4px;
  color: #ffffff;
}

.mainHeading__title {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
  margin-bottom: 0;
  font-size: 40px;
  color: #ffffff;
  position: relative;
}

.mainHeading__title::before {
  position: absolute;
  content: "";
  width: 20%;
  height: 5px;
  background: #c5c5c5;
  bottom: -15px;
  z-index: 1;
  left: 40%;
  right: 0;
}

.mainHeading__image {
  max-width: 200px;
  width: 200px;
  background-color: #96aaec;
  padding: 1rem;
  border-radius: 0;
  transform: translate(0px, -2rem);
}

.mainHeading__image img {
  width: 100%;
}

@media screen and (max-width: 767px) {
  .headingBox {
    padding: 1rem 0rem;
  }

  .mainHeading__title {
    font-size: 30px;
  }
}

.st-box {
  box-shadow: -8px 10px 4px 0px #0000001c;
}

.st-table {
  border: 1px solid #e4e4e4;
  width: 100%;
  border-collapse: collapse;
}

.st-table thead th {
  background-color: #2d3b6e;
  color: #e4e4e4;
  padding: 1.2rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 400;
}

.st-table thead th:first-child {
  text-align: center;
}

.st-table tbody td {
  border-top: 1px solid #e4e4e4;
  padding: 1.2rem;
  text-align: left;
  color: #1b3c73;
}

.st-table tbody td:first-child {
  /* text-decoration: underline; */
  text-align: center;
}

.st-table tbody td:first-child::before {
  /* content: "#"; */
  font-size: 0.8rem;
}

#publication-table tbody td:first-child::before {
  content: "" !important;
  font-size: 0.8rem;
}

a.dwn-link {
  font-size: 0.8rem;
  color: #c00707;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: 1px solid #c00707;
  padding: 0.25rem 0.5rem;
  border-radius: 1rem;
  transition: all 0.3s ease-in-out;
  max-width: 120px;
}

a.dwn-link:hover,
a.dwn-link:active,
a.dwn-link:focus {
  background-color: #c00707;
  color: #ffffff;
}

.ot-table {
  border: 1px solid #e4e4e4;
  width: 100%;
  border-collapse: collapse;
}

.ot-table thead th {
  background-color: #2d3b6e;
  color: #e4e4e4;
  padding: 1.2rem;
  text-transform: uppercase;
  font-size: 0.9rem;
  text-align: left;
  font-weight: 400;
}

.ot-table thead th:first-child {
  text-align: center;
}

.ot-table tbody td {
  border-top: 1px solid #e4e4e4;
  padding: 1.2rem;
  text-align: left;
  color: #1b3c73;
}

.ot-table tbody td:first-child {
  text-decoration: underline;
  text-align: center;
}

.ot-table tbody td:first-child::before {
  content: "";
  font-size: 0.8rem;
}

.order-detail-bold-color {
  color: #3151b4;
}

.product-details-back-btn {
  right: 20px;
  text-decoration: none;
  top: 10px;
  outline: #3151b4;
  padding: 0px;
  border: none;
  background-color: white;
  color: #3151b4;
}

.product-details-back-btn::before {
  content: "← ";
}

.sec-services .service-card {
  color: #1b3c73;
  padding: 2rem;
  border: 1px solid #b4b4b4;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

.sec-services a {
  color: #1b3c73;
}

.sec-services :hover .service-card {
  border: 1px solid #414abb;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

.sec-services .sec-header {
  display: flex;
  align-items: center;
  gap: 20px;
}

.sec-services .sec-header .sec-title {
  font-size: 1rem;
  font-weight: 500;
  color: #2a3e61;
}

.sec-services .section-icon {
  background: #455c97;
  border-radius: 0;
  text-align: center;
  font-size: 20px;
  padding: 10px;
  color: #fff;
  margin: 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -6px 4px 4px 0px #0000001c;
}

.sec-services ul {
  margin: 0.375rem 0;
  padding: 1rem 0;
}

.sec-services .img-container {
  max-width: 500px;
  width: 100%;
  position: relative;
  background: #ebeff5;
  margin-top: 10px;
  margin-left: 10px;
}

.sec-services .img-container img {
  object-fit: contain;
  max-width: 100%;
  transform: translate(-10px, -10px);
  -webkit-transform: translate(-10px, -10px);
  -moz-transform: translate(-10px, -10px);
  box-shadow: 10px 10px 13px 0px #00000063;
}

.sec-services ul li {
  list-style-type: none;
  padding: 0.375rem 0.5rem;
  color: #1b3c73;
  background: #7391c41c;
  border: 1px solid #7391c41c;
  border-left: 3px solid #1b3c73;
  margin-bottom: 0.5rem;
  font-size: 0.9rem;
}

.sec-services ul li a {
  text-decoration: none;
  cursor: pointer;
}

.sec-services p {
  font-size: 0.9rem;
}

.sec-contact .contact-icon {
  background: #455c97;
  border-radius: 0;
  text-align: center;
  font-size: 30px;
  padding: 12px;
  color: #fff;
  margin: 0;
  transition: 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

.sec-contact .main-title {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 600;
  color: #3e5c8f;
}

.sec-contact .main-title span {
  color: #2a3e61;
}

.sec-contact .contact-card .sub-title {
  color: #2a3e61;
}

.sec-contact .contact-card {
  padding: 1rem;
  border: 1px solid #b4b4b4;
  margin: 0px;
  text-align: center;
  height: 250px;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

.sec-contact .contact-card:hover {
  border: 1px solid #414abb;
  box-shadow: none;
}

.sec-contact iframe {
  padding: 1rem;
  border: 1px solid #b4b4b4;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

.sec-contact .contact-card:hover,
.sec-contact iframe:hover {
  border: 1px solid #414abb;
  box-shadow: none;
}
