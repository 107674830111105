#orderDetails .orderDetails-card {
    padding: 2.5rem 2.5rem !important;
    border: 1px solid #b4b4b4 !important;
    box-shadow: -8px 10px 4px 0px #0000001c !important;
}

#orderDetails .orderDetails-card:hover {
    border: 1px solid #414abb !important;
    box-shadow: 8px 10px 4px 0px #0000001c !important;
}

#orderDetails .error {
    font-size: 12px !important;
    color: red !important;
}

#orderDetails label {
    font-size: 14px !important;
}

#orderDetails .link {
    font-size: 14px;
}

#orderDetails input {
    font-size: 16px;
}