/* Main theme colors */
.theme-1 {
  background-color: #1b3c73;
  padding: 0.75rem 1rem;
}

.sign-in-btn:hover {
  background-color: white !important;
  color: #1b3c73 !important;
  border: 2px solid #1b3c73 !important;
}

.signup-link {
  color: #1b3c73;
  text-decoration: underline;
}

.theme {
  background-color: #001a66;
}

.table-header {
  background-color: #1b3c73 !important;
}

.background {
  background-color: #1b3c73;
}

/* Custom Text Colors */
.text-theme-1 {
  color: #1b3c73;
}

.text-theme {
  color: #1b3c73;
}

#ecf5ffa8 .cat-bg-1 {
  background-color: white;
}

.cat-bg-2 {
  background-color: #ecf5ffa3;
}

.cat-bg-3 {
  background-color: #e4f1ff;
}

.cat-bg-4 {
  background-color: #c4ddf8c4;
}

.footer-bg {
  background-color: #0c2c8b;
}

.navbar {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  font-family: "Poppins", sans-serif;
  /* Modern font */
}

.navbar .nav-link {
  font-weight: 400;
  font-size: 15px;
  transition: color 0.3s ease;
  position: relative;
}

.navbar .nav-link {
  position: relative;
  /* Required for the absolute positioning of the pseudo-element */
  color: #fff;
  /* Ensure the text color is white */
}

.navbar .nav-link:hover {
  color: #313999;
  /* Change color on hover */
}

li.nav-item {
  padding: 10px;
  /* Padding for each nav item */
}

.navbar .nav-link::after {
  content: "";
  position: absolute;
  width: 0;
  /* Start with zero width */
  height: 2px;
  background: white;
  /* Change background color of the line */
  bottom: 0px;
  /* Position below the text */
  left: 0;
  transition: width 0.3s ease;
  /* Smooth transition for the line width */
}

/* Show the underline effect on hover */
.navbar .nav-link:hover::after {
  width: 100%;
  /* Extend the line width to full when hovered */
}

/* Show the underline effect when the link is active */
.navbar .nav-link.active::after {
  width: 100%;
  /* Extend the line width to full when active */
  color: #313999;
  /* Change color of the active link if needed */
}

.dropdown-menu.theme {
  background-color: #1b3c73;
  border: none;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 1rem;
  font-family: "Poppins", sans-serif;
}

.dropdown-item {
  font-weight: 500;
  padding: 0.75rem 1.25rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.dropdown-item:hover {
  background-color: #313999;
  color: #1b3c73;
  transform: translateX(5px);
  border-radius: 5px;
}

.top-logo {
  width: 100%;
  max-width: 60px;
  padding: 5px;
  border-radius: 10px;
  /* margin-left: 10px; */
}

.position-relative .btn.theme {
  background-color: transparent;
  border: none;
  position: relative;
}

.position-relative .btn.theme .badge {
  position: absolute;
  top: -10px;
  right: -10px;
  padding: 3px 9px;
  font-size: 12px;
  background-color: #ff4b4b;
  color: white;
  border-radius: 50%;
  font-weight: bold;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
}

.fa-user-circle {
  transition: transform 0.3s ease, color 0.3s ease;
}

.fa-user-circle:hover {
  color: #313999;
  transform: scale(1.1);
}

.navbar-toggler {
  border: none;
  outline: none;
  color: white;
}

.navbar-toggler-icon {
  background-color: #fff;
  width: 24px;
  height: 2px;
  position: relative;
  display: block;
}

.navbar-toggler-icon::before,
.navbar-toggler-icon::after {
  content: "";
  background-color: #fff;
  width: 100%;
  height: 2px;
  position: absolute;
  left: 0;
  transition: all 0.3s ease;
}

.navbar-toggler-icon::before {
  top: -8px;
}

.navbar-toggler-icon::after {
  top: 8px;
}

.btn-theme:hover {
  background-color: #313999;
  color: #1b3c73;
}

.btn {
  background-color: #313999;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn:hover {
  background-color: #313999;
  color: #1b3c73;
}

.btn .fa-shopping-cart {
  transition: color 0.3s ease;
}

.btn:hover .fa-shopping-cart {
  color: #313999;
}

@media (max-width: 768px) {
  .navbar {
    padding: 1rem;
  }

  .navbar .nav-link {
    font-size: 1rem;
  }

  img.top-logo {
    max-width: 50px;
  }
}

.cart {
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

#login .login-card {
  padding: 2.5rem 2.5rem;
  border: 1px solid #b4b4b4;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

#login .login-card:hover {
  border: 1px solid #414abb;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

#login .error {
  font-size: 12px !important;
  color: red !important;
}

#login label {
  font-size: 14px !important;
}

#login .link {
  font-size: 14px;
}

#login input {
  font-size: 16px;
}

#signup .signup-card {
  padding: 2.5rem 2.5rem;
  border: 1px solid #b4b4b4;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

#signup .signup-card:hover {
  border: 1px solid #414abb;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

#signup .error {
  font-size: 12px !important;
  color: red !important;
}

#signup label {
  font-size: 14px !important;
}

#signup .link {
  font-size: 14px;
}

#signup input {
  font-size: 16px;
}

#fs .fs-card {
  padding: 2.5rem 2.5rem;
  border: 1px solid #b4b4b4;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

#fs .fs-card:hover {
  border: 1px solid #414abb;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

#fs .error {
  font-size: 12px !important;
  color: red !important;
}

#fs label {
  font-size: 14px !important;
}

#fs .link {
  font-size: 14px;
}

#fs input {
  font-size: 16px;
}