body,
html {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif !important;
}

.page-link {
  color: #3151b4 !important;
}

.cart-image {
  max-width: 120px;
}

.avatar-label {
  color: white;
  z-index: 1;
}

.cursor-pointer {
  cursor: pointer;
}

.fixed-div {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3 !important;
  background-color: rgba(100, 100, 100, 0.7);
}

.close-icon {
  position: absolute !important;
  top: 10px !important;
  right: 10px !important;
  cursor: pointer;
}

.avatar-div {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 180px;
  height: 180px;
  left: 0;
  right: 0;
  background-color: rgba(100, 100, 100, 0.7);
  display: none !important;
}

.pf-card {
  padding: 2rem;
  border: 1px solid #b4b4b4;
  transition: 0.5s;
  box-shadow: -8px 10px 4px 0px #0000001c;
}

.pf-card:hover {
  border: 1px solid #414abb;
  box-shadow: 8px 10px 4px 0px #0000001c;
}

.avatar-flex {
  display: flex !important;
}

.text-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 100%;
  white-space: normal;
  min-height: 45px;
}


.required:after {
  content: " *";
  color: red;
}

.profile-link {
  text-decoration: none;
  color: #000000;
  width: 100%;
  cursor: pointer;
}

.profile-link:hover {
  color: #3151b4;

}

.start-50 {
  left: 85% !important;
}

.google-maps {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
}

.google-maps iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}

.bg-facebook {
  background-color: #3b5998;
}

.bg-twitter {
  background-color: #1da1f2;
}

.bg-google {
  background-color: #ea4335;
}

.bg-instagram {
  background-color: #e1306c;
}

.bs-cover {
  background-size: cover;
}

.r-0 {
  right: 0;
}

.mw-180 {
  max-width: 180px;
}

.mw-140 {
  max-width: 140px;
}

.i-va {
  vertical-align: -0.125em;
}

.breadcrumb {
  padding: 0.5rem 1rem;
  background-color: #e9ecef;
}

.accordion-icon-button {
  display: flex;
  border-radius: inherit;
}

.accordion-icon-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%2016'%20fill%3D'%23%7B%24accordion-icon-color%7D'%3E%3Cpath%20fill-rule%3D'evenodd'%20d%3D'M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z'%2F%3E%3C%2Fsvg%3E");
  transform: rotate(-180deg);
  background-position-y: center;
}

.accordion-icon-button::after {
  flex-shrink: 0;
  background-position-y: center;
  width: 20px;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2016%2016'%20fill%3D'%23%7B%24accordion-icon-active-color%7D'%3E%3Cpath%20fill-rule%3D'evenodd'%20d%3D'M1.646%204.646a.5.5%200%200%201%20.708%200L8%2010.293l5.646-5.647a.5.5%200%200%201%20.708.708l-6%206a.5.5%200%200%201-.708%200l-6-6a.5.5%200%200%201%200-.708z'%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}

.accordion-icon-button.collapsed {
  border-bottom: none;
}

.btn {
  border-radius: 40px;
  color: white;
}

.btn:hover {
  color: white !important;
}

.card,
.card-header {
  border-radius: 0px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 50;
}


#profile .error {
  font-size: 14px !important;
  color: red !important;
}

#profile label {
  font-size: 14px !important;
}

.out-stock-button:hover {
  border: 1px solid red !important;
  background-color: red !important;
  color: white !important;
}

.react-tel-input input {
  width: 100% !important;
  border-radius: 0px !important;
  padding: 28px 60px !important;
  font-size: 1rem !important;
  font-weight: 400 !important;
  line-height: 1.5rem !important;
  border: 1px solid #dee2e6 !important;
}

.flag-dropdown {
  padding: 5px !important;
  border: 1px solid #dee2e6 !important;
}