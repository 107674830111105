
button.btn-1 {
    border: none;
    padding: 8px;
    border-radius: 5px;
}
.btn:hover{
    border: inherit;
 background-color: #3152b4;
    color: inherit;
}
.signin-form{
    display: flex;
    justify-content: center;
    align-items: center;
}

.error-msg{
    font-size: 14px;
    margin-top: 5px;
}

.eye-icon{
    position: absolute !important;
    right: 0px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    padding-right: 15px !important;
}