.cart-sidebar {
  position: fixed;
  top: 0;
  right: -100%;
  /* Off-screen initially */
  width: 300px;
  height: 100%;
  overflow-y: auto;
  /* Enable vertical scrolling */
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.3);
  transition: right 0.3s ease-in-out;
  z-index: 9999;
}

.disabled-btn{
  background-color: #3152b4 !important;
}

.cart-sidebar.open {
  right: 0;
  /* Slide in when open */
}

body.no-scroll {
  overflow: hidden;
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #ddd;
}

.sidebar-first-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 64px;
  width: 100%;

  border-bottom: 1px solid #ddd;
  z-index: 2;
}

.close-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
}

.sidebar-title {
  color: rgb(31, 30, 30);
}

.sidebar-content {
  padding: 15px 0px 15px 15px;
  overflow-y: auto;
  height: 85vh;
}

.cart-items-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 50px;
  overflow-y: auto;
}

.cart-item-card {
  display: flex;
  gap: 20px;

  justify-content: space-between;
  align-items: center;
  padding: 15px 0px;
  border-bottom: 1px solid #ddd;
}

.cart-item-image {
  width: 100%;
  height: 128px;
  max-width: 90px;

  box-shadow: -3px 3px 3px rgba(0, 0, 0, 0.5);
}

.cart-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cart-item-title {
  font-size: 14px;
  font-weight: bold;
  margin: 0;
}

.cart-item-quantity {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.cart-item-quantity button {
  background: none;
  border: 1px solid #ddd;
  padding: 5px;
  cursor: pointer;
}

.cart-item-quantity span {
  padding: 0 10px;
}

.cart-item-price {
  font-size: 14px;
  color: #333;
}

.remove-item {
  background: none;
  border: none;
  color: #6c757d;
  cursor: pointer;
  font-size: 11px;
  padding-left: 0px;
}

.sidebar-footer {
  padding: 15px;
  background-color: #f5f5f5;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.checkout-btn {
  width: 100%;
  padding: 10px;
  border-radius: 30px;
  background-color: #3152b4;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}

.checkout-btn:hover {
  width: 100%;
  padding: 7px 10px;
  border-radius: 30px;
  background-color: #fff;
  border: 2px solid #3152b4;
  color: #3152b4;
  font-size: 18px;
  cursor: pointer;
  text-align: center;
}

.continue-btn {
  width: 100%;
  padding: 10px;
  background-color: #838383;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
}

.empty-cart {
  text-align: center;
  font-size: 16px;
  padding: 20px;
}

.cart-title {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 600;
}

.increase {
  width: 100%;
  max-width: 130px;
}

.cart-item-info {
  max-width: 160px;
  width: 100%;
}