.about-up {
  margin-top: 150px;
}

/*abut*/
.main-about {
  position: relative;
  overflow: hidden;
  padding-top: 180px;
}

.main-about:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  background: #f1eeee;
  width: 490px;
  height: 100%;
  z-index: -1;
}

.abut-text-img {
  position: relative;
  text-align: center;
}

.abut-text-img:before {
  position: absolute;
  content: "";
  top: 35px;
  width: 10%;
  right: 2px;
  height: 85%;
  background: #414abb;
  z-index: -1;
}

.abut-text .big-heading span {
  color: #414abb;
  font-size: 18px;
  font-weight: 500;
  margin: -5px 0 0;
  display: block;
  font-family: "Poppins", sans-serif;
}

.abut-text {
  padding: 0 0 0 50px;
}

.abut-text h5 {
  color: #1b1924;
  font-size: 27px;
  font-weight: 600;
}

.abut-text label {
  color: #857676;
  margin: 0;
}

.dot-6 {
  position: absolute;
  top: 100px;
  left: 85px;
  animation: zoom-in-zoom-out 20s ease infinite;
  z-index: -1;
}

@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }

  50% {
    transform: scale(1.2, 1.2);
  }

  100% {
    transform: scale(1, 1);
  }
}

.main-body {
  background: url(../assets/images/bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  padding: 70px 0 0;
}

.main-body-text .big-heading:before {
  margin: 0;
}

.main-body-text .big-heading {
  font-size: 30px;
  color: #0c2d62;
}

.main-body-text .table {
  width: 62%;
  margin: 30px auto;
}

.main-body-text tr th h3 {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  background: #3152b4;
  padding: 13px 30px;
  margin: 0;
}

.main-body-text tr th {
  padding: 0;
}

.main-body-text tr td .fa {
  color: #414abb;
}

/*inventry-main*/
.inventry-main {
  border: 1px solid #ffdae6;
  border-radius: 10px;
  padding: 25px;
  margin: 25px 0 0;
  background: #ffffffcc;
}

.inventry-main-text .big-heading {
  text-align: center;
  color: #3152b4;
  font-size: 30px;
  font-weight: 500;
  margin: 0 0 30px;
}

.inventry-main-text .big-heading:before {
  margin: 0 auto;
}

.inventry {
  padding: 20px;
  background: #f6f7ff;
  margin: 30px 0 0;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #f6f7ff;
  height: 190px;
}

.inventry h5 {
  color: #3152b4;
  margin: 0 0 10px;
  font-size: 22px;
  font-weight: 800;
}

.inventry p {
  text-align: center;
}

.inventry-main .col-md-4:nth-child(2) .inventry {
  background: #8dceb8;
}

.inventry-main .col-md-4:nth-child(3) .inventry {
  background: #ffbc59;
}

.inventry-main .col-md-4:nth-child(4) .inventry {
  background: #a3bde0;
}

.inventry-main .col-md-4:nth-child(5) .inventry {
  background: #d7df24;
}

.inventry-main .col-md-4:nth-child(6) .inventry {
  background: #8fe292;
}

.inventry-main .col-md-4:nth-child(7) .inventry {
  background: #ffb2e0;
}

.inventry-main .col-md-4:nth-child(8) .inventry {
  background: #e3cca2;
}

.inventry-main .col-md-4:nth-child(9) .inventry {
  background: #96fbff;
}

.inventry-main .col-md-4:nth-child(10) .inventry {
  background: #e7adab;
}

.inventry-main h6 {
  text-align: center;
  margin: 30px 0 0;
  font-size: 18px;
  background: #f6f7ff;
  padding: 15px;
  border-radius: 10px;
  font-weight: 400;
}

/*.counter*/
.counter {
  background: #1c1a25;
  background-size: cover;
  width: 100%;
  right: 150px;
  bottom: 80px;
  padding: 60px 50px;
  background-attachment: fixed;
}

.counter-Txt {
  color: #fff;
  text-align: center;
  border-left: 1px solid #626161;
}

.counter-Txt .fa {
  display: block;
  font-size: 40px;
  color: #414abb;
}

.counter-Txt span {
  font-size: 48px;
  font-weight: 600;
}

.counter-Txt h4 {
  font-size: 18px;
  font-weight: 300;
  margin: 0;
}

.counter .col-md-4:nth-child(1) .counter-Txt {
  border-left: 0;
}

.big-heading-center {
  margin: 0 0 30px;
  padding: 0;
  font-size: 40px;
  color: #111636;
  font-weight: 500;
  position: relative;
  font-family: "Poppins", sans-serif;
  text-align: center;
}

.big-heading-center:before {
  position: absolute;
  content: "";
  width: 100px;
  height: 5px;
  background: #c5c5c5;
  bottom: -15px;
  z-index: 1;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.big-heading-center:after {
  position: absolute;
  width: 30px;
  height: 5px;
  background: #414abb;
  content: "";
  left: 0;
  bottom: -30px;
  margin: 0 auto;
  right: 0;
}
 
@media only screen and (max-width:600px) {
  .abut-text {
    padding: 0;
    margin-top: 30px;
  }
}